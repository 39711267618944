import * as React from 'react'
import cn from 'classnames'

export type BodyProps = {
  children: React.ReactNode
  className?: string
}

export const Body: React.VFC<BodyProps> = (props) => {
  const { children, className } = props

  return (
    <div
      className={cn(
        'flex flex-row items-center gap-3 flex-shrink-0',
        className
      )}
    >
      {children}
    </div>
  )
}
